export const FirebaseConfig = {
	"projectId": "towit-bf0d4",
	"appId": "1:578138849148:web:126fc820ee18665c276ef9",
	"databaseURL": "https://towit-bf0d4-default-rtdb.firebaseio.com",
	"storageBucket": "towit-bf0d4.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBEV9ft5rb9T8thWW9qsaSBvu6UDXBSxLY",
	"authDomain": "towit-bf0d4.firebaseapp.com",
	"messagingSenderId": "578138849148",
	"measurementId": "G-S8HNT2X6VL"
};